import skipToMainContentTemplate from './skip-to-main-content-template';

const defaultConfig = {
  text: 'Skip to main content',
  skipToMainContentContainer: document.body
};

const getConfig = (cfg) => cfg ? Object.assign({}, defaultConfig, cfg || {}) : null;


function showSkipToMainContentElement(config) {
  const tpl = document.createElement('template');
  tpl.innerHTML = skipToMainContentTemplate(config);
  config.skipToMainContentContainer.insertBefore(tpl.content, config.skipToMainContentContainer.firstChild);
}

function init(cfg) {
  const config = getConfig(cfg);
  if(!config) {
    return;
  }
  showSkipToMainContentElement(config);
}

window.PFS = window.PFS || {};
window.PFS.components = window.PFS.components || {};
window.PFS.components.skipToMainContent = { init };

export default { init };
